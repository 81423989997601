import React from "react"
import Layout from "../components/layout"
import indexStyles from "./index.module.css"
import Content_surround from "../components/content_surround"

export default function Contact() {
  return (
    <Layout image="https://admhotels.com/images/business_contact.jpg" page_title="Contact ADM">
      <div className={indexStyles.wrapper}> 
        <div className="stdSection">
        <h2 className="text-center">Have a Question?</h2>
        <p className="text-center" style={{color:'goldenrod',fontWeight:'bold',fontSize:'20px'}}>Contact us by phone or email.</p>
          <Content_surround goTo="">
            <p className="text-center">phone: 312-933-4642</p>            
            <p className="text-center">email: info@admhotels.com</p>

            <div className="text-center">office: &nbsp;
            <a href="#">
            1657 Commerce Dr Suite #16A, South Bend, IN 46628
            </a>
            </div>
          </Content_surround> 
        </div>
      </div>
    </Layout>



  ) 
}
